export const mailLang = {
  langType: [
    {
      id: 'ar',
      name: 'العربية',
      value: 'ar'
    },

    {
      id: 'en',
      name: 'الأنجليزية',
      value: 'en'
    }
  ]
}

export const smsLang = {
  langType: [
    {
      id: 'ar',
      name: 'العربية',
      value: 'ar'
    },

    {
      id: 'en',
      name: 'الأنجليزية',
      value: 'en'
    }
  ]
}
