<template>
  <div class="account-details">
    <div class="row">
      <div class="col-lg-6">
        <Account />
      </div>

      <div class="col-lg-6">
        <MailingList />
      </div>
    </div>
  </div>
</template>

<script>
import Account from './Account'
import MailingList from './MailingList'

export default {
  name: 'AccountDetails',
  components: { Account, MailingList }
}
</script>
