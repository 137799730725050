<template>
  <div class="w-100">
    <AccountDetails />
  </div>
</template>

<script>
import AccountDetails from '@/components/profile/settings/account-details/Index.vue'
export default {
  name: 'AccountDetailsPage',
  components: { AccountDetails }
}
</script>
