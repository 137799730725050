<template>
  <div class="base-date-input">
    <div class="d-flex justify-content-between align-items-center m-b-10">
      <label
        :for="inputId"
        class="regular-font fs-14 font-weight-600"
      >{{
        label
      }}</label>
      <span
        v-if="message"
        class="error-message regular-font"
      >{{
        message
      }}</span>
    </div>
    <!-- <datepicker
      v-model="dateValue"
      value-type="format"
      default-value="1995-01-01"
      :placeholder="placeholder"
      :append-to-body="false"
      :disabled="disabled"
      :editable="false"
      :type="type"
      startFrom="startFrom"
      :has-radius="hasRadius"
      :clearable="false"
      :style="{ width: fixedWidth || '' }"
      :class="[
        hasError ? 'wrong-data' : '',
        !!value && !hasError ? 'valid-data' : '',
      ]"
      @input="updateDate"
    /> -->
  </div>
</template>

<script>
// import Datepicker from '../src/datepicker/Datepicker.vue'
export default {
  name: 'BaseDateInput',
  // components: { Datepicker },

  props: {
    inputId: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    value: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultValue: {
      type: String,
      required: false,
      default: '1995-01-01'
    },
    type: {
      type: String,
      required: false,
      default: 'default'
    },
    fixedWidth: {
      type: String,
      required: false,
      default: ''
    },
    takeFullWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    hasRadius: {
      type: Boolean,
      required: false,
      default: true
    },
    hasError: {
      type: Boolean,
      required: false,
      default: true
    },

    message: {
      type: String,
      required: false,
      default: null
    }
  },

  data () {
    return {
      dateValue: ''
    }
  },

  methods: {
    updateDate () {
      this.$emit('update-selected', {
        value: this.dateValue,
        id: this.inputId
      })
    }
  }
}
</script>

<style lang="scss">
@import './base-date-input';
</style>
