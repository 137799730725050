export const lookups = {
  genderTypes: [
    {
      id: 'male',
      name: 'home.male',
      value: 'male'
    },

    {
      id: 'female',
      name: 'home.female',
      value: 'female'
    }
  ]
}
