<template>
  <div class="mailing-list">
    <h3 class="bold-font fs-20 black-color-text m-b-10 mt-sm-5">
      {{ $t('account.list') }}
    </h3>
    <p class="fs-16 light-gray-color-text">
      {{ $t('account.subscribe') }}
    </p>

    <BaseCheckbox
      input-id="subscribe"
      :value="form.subscribe"
      :is-checked="form.subscribe"
      :label="$t('account.agree')"
      class="m-y-20"
      label-color-class="main-color-text"
      @toggle="toggleSubscribe"
    />

    <div class="row">
      <div class="col-6">
        <base-dropdown
          input-id="mailLang"
          :options="mailLang.langType"
          :value="form.mailLang"
          take-full-width
          :has-shadow="false"
          has-border
          :has-radius="false"
          :label="$t('forms.mailLang')"
          class="m-t-18"
          :has-error="errors.has('mailLang')"
          :message="errors.get('mailLang')"
          @reset="errors.clear()"
          @update-seletced="updateMailLang"
        />
      </div>

      <div class="col-6">
        <base-dropdown
          input-id="smsLang"
          :options="smsLang.langType"
          :value="form.smsLang"
          take-full-width
          :has-shadow="false"
          has-border
          :has-radius="false"
          :label="$t('forms.smsLang')"
          class="m-t-18"
          :has-error="errors.has('smsLang')"
          :message="errors.get('smsLang')"
          @reset="errors.clear()"
          @update-seletced="updateSmsLang"
        />
      </div>
    </div>

    <base-button
      :text="$t('account.updateList')"
      bg-color-class="light-gray-color-bg-with-opacity"
      color-class="dark-gray-color-bg"
      is-medium
      class="m-t-18"
    />
  </div>
</template>

<script>
import Form from '@/services/Form'
import Errors from '@/services/Errors'
import BaseCheckbox from '@/components/partials/base-checkbox/Index.vue'
import { mailLang, smsLang } from '@/static/lang.js'

export default {
  name: 'MailingList',
  components: { BaseCheckbox },
  data () {
    return {
      form: new Form({
        mailLang: 'ar',
        smsLang: 'ar',
        subscribe: true
      }),

      mailLang,
      smsLang,

      errors: new Errors()
    }
  },

  methods: {
    toggleSubscribe () {
      this.form.subscribe = !this.form.subscribe
    },
    updateMailLang (value) {
      this.form.mailLang = value.value
    },

    updateSmsLang (value) {
      this.form.smsLang = value.value
    }
  }
}
</script>

<style lang="scss" scoped>
.mailing-list {
  @media (max-width: 767px) {
    margin-top: 50px;
  }
}
</style>
