<template>
  <div class="account">
    <h3 class="bold-font fs-20 black-color-text m-b-10">
      {{ $t('account.title') }}
    </h3>
    <p class="fs-16 light-gray-color-text">
      {{ $t('account.text4') }}
    </p>

    <form @submit.prevent="updateProfile()">
      <div class="row">
        <div class="col-12">
          <BaseTextInput
            :value="form.name"
            :placeholder="$t('forms.enterName')"
            input-id="name"
            class="m-t-18"
            type="text"
            :label="$t('forms.firstName')"
            :has-error="errors.has('name')"
            :message="errors.get('name')"
            @reset="errors.clear()"
            @submit="validateData"
            @update-value="getLoginData"
          />
        </div>

        <div class="col-12">
          <BaseTextInput
            :value="form.phone"
            :placeholder="$t('forms.enterPhone')"
            input-id="phone"
            class="m-t-18"
            type="text"
            maxlength="11"
            :label="$t('forms.phone')"
            :has-error="errors.has('phone')"
            :message="errors.get('phone')"
            @reset="errors.clear()"
            @submit="validateData"
            @isNumber="isNumber"
            @update-value="getLoginData"
          />
        </div>

        <div class="col-12">
          <BaseTextInput
            :value="form.email"
            :isReadOnly="true"
            :placeholder="$t('forms.enterEmail')"
            input-id="email"
            class="m-t-18"
            type="text"
            :label="$t('forms.email')"
            :has-error="errors.has('email')"
            :message="errors.get('email')"
            @reset="errors.clear()"
            @submit="validateData"
            @update-value="getLoginData"
          />
        </div>

        <div class="col-12">
          <BaseDateInput
            input-id="birthDate"
            class="m-t-18"
            :placeholder="placeholder"
            :has-radius="false"
            :label="$t('forms.birthDate')"
            :has-error="errors.has('user_birthday')"
            :message="errors.get('user_birthday')"
            @reset="errors.clear()"
            @update-selected="updateDate"
          />
        </div>

        <div
          v-if="cities.length"
          class="col-12 col-md-6"
        >
          <base-dropdown
            input-id="cities"
            :options="cities"
            :value="form.city"
            take-full-width
            :has-shadow="false"
            has-border
            :has-radius="false"
            :label="$t('forms.city')"
            class="m-t-18"
            :has-error="errors.has('city')"
            :message="errors.get('city')"
            @reset="errors.clear()"
            @update-seletced="updateCity"
          />
        </div>

        <div
          v-if="cities.length"
          class="col-12 col-md-6"
        >
          <base-dropdown
            input-id="districts"
            :options="districts"
            :value="form.district"
            take-full-width
            :has-shadow="false"
            has-border
            :has-radius="false"
            :label="$t('forms.districts')"
            class="m-t-18"
            :has-error="errors.has('district')"
            :message="errors.get('district')"
            @reset="errors.clear()"
            @update-seletced="updateSeletced"
          />
        </div>

        <div class="col-12">
          <base-dropdown
            input-id="gender"
            :options="lookups.genderTypes"
            :value="form.gender"
            take-full-width
            :has-shadow="false"
            has-border
            :has-radius="false"
            :label="$t('forms.gender')"
            class="m-t-18"
            :has-error="errors.has('gender')"
            :message="errors.get('gender')"
            @reset="errors.clear()"
            @update-seletced="updateGender"
          />
        </div>

        <div class="col-12">
          <base-button
            :text="$t('forms.save')"
            bg-color-class="main-color-bg"
            is-medium
            class="m-t-18"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Form from '@/services/Form'
import Errors from '@/services/Errors'
import BaseDateInput from '@/components/partials/base-date-input/Index.vue'
// import isEmailValid from '@/utils/isValidEmail'
import { lookups } from '@/static/gender.js'
import { mapGetters } from 'vuex'

export default {
  name: 'Account',
  components: { BaseDateInput },
  data () {
    return {
      form: new Form({
        name: '',
        phone: '',
        email: '',
        city: '',
        district: '',
        gender: '',
        user_birthday: ''
      }),

      lookups,
      user_id: '',
      placeholder: '',

      errors: new Errors()
    }
  },

  computed: {
    // ...mapGetters('locations', ['cities', 'districts']),
    cities () {
      return this.$store.state.locations.allCities
    },

    districts () {
      return this.$store.state.locations.allDistricts
    },

    ...mapGetters('Globals', ['userInfo', 'userPhone'])
  },

  mounted () {
    this.getLocations()
    this.getDistricts()
    this.getBirthdayPlaceholder()
    this.SetUserData()
  },

  methods: {
    getLocations () {
      this.$store.dispatch('locations/getAllCountries')
    },

    SetUserData () {
      this.form.phone = this.userInfo.users_mobile
      if (this.userInfo.users_email == 'null') {
      } else {
        this.form.email = this.userInfo.users_email
      }
      this.form.name = this.userInfo.users_fname

      // Set City Name
      if (this.userInfo.users_government == null) {
        this.type = this.$t('forms.chooseCity')
      } else {
        const foundCity = this.cities.map((el) => {
          return el
        })
        const city = foundCity.find((el) => {
          if (el.value == this.userInfo.users_government) {
            return el
          }
        })

        this.type = city.name
        this.form.city = city.value
      }
      // Set District Name

      if (this.userInfo.users_area == null) {
        this.type = this.$t('forms.chooseDistrict')
      } else if (this.form.city) {
        const found = this.districts.find((el) => {
          if (el.value == this.userInfo.users_area) {
            return el
          }
        })
        this.type = found.name
        this.form.district = found.value
      }

      /// Set Birthdate
      this.form.user_birthday = this.userInfo.users_birthday

      // Set Gender

      this.form.gender = this.userInfo.gender
    },

    updateSeletced (value) {
      this.form.district = value.value
    },

    updateGender (value) {
      this.form.gender = value.value
    },

    updateDate (value) {
      this.form.user_birthday = value.value
      if (this.userInfo.user_birthday == null) {
      } else {
        this.form.user_birthday = this.userInfo.users_birthday
      }
    },

    isNumber (event) {
      if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault()
    },

    validateData () {
      this.validateEmail(),
      this.validatePhoneNumber(),
      ['name', 'city', 'district', 'gender', 'user_birthday'].forEach(
        (el) => {
          if (this.form[el] === '' || this.form[el] === null) {
            this.errors.recordField({
              [el]: `${this.$t(`forms.${el}`)}: ${this.$t(
                'forms.validValue'
              )}`
            })
          }
        }
      )
    },

    updateProfile () {
      if (!this.errors.hasAny()) {
        const updateProfileData = {
          token: this.$store.state.Globals.idToken,
          first_name: this.form.name || this.userInfo.users_fname,
          email: this.form.email || this.userInfo.users_email,
          gender: this.form.gender || this.userInfo.gender,
          mobile: this.form.phone || this.userInfo.users_mobile,
          users_government: this.form.city || this.userInfo.users_government,
          users_area: this.form.district || this.userInfo.users_area,
          users_birthday:
            this.form.user_birthday || this.userInfo.users_birthday,
          app_version: ''
        }

        localStorage.setItem(
          'updateProfileData',
          JSON.stringify(updateProfileData)
        )
        delete this.form.originalData
        this.$store
          .dispatch('Globals/completeUserRegister', updateProfileData)
          .then(() => {})
      }
    },

    updateCity (value) {
      this.form.city = value.value
      this.form.district = ''
      this.getDistricts()
    },

    getDistricts () {
      const cityPayload = {
        token: this.getUserToken,
        city_id: this.form.city || this.userInfo.users_government
      }

      this.$store
        .dispatch('locations/getAllDistricts', cityPayload)
        .then(() => {})
    },

    getBirthdayPlaceholder () {
      if (this.userInfo.users_birthday == null) {
        this.placeholder = this.$t('forms.enterBirthDate')
      } else {
        this.placeholder = this.userInfo.users_birthday
      }
    }
  }
}
</script>

<style scoped>
:deep(.dropdown-body.white-color-bg.p-y-18.p-x-16.radius-8) {
  width: 100% !important;
  height: 150px !important;
  overflow-y: scroll !important;
}
</style>
